import React from 'react'
import Footer from '../../components/footer/Footer'
import TopMenu from '../../components/topbar/TopMenu'
import Booking from '../users/booking/Booking'

// import Booking from '../users/clients/Booking'
const Home = () => {
  return (
    <div>
      <TopMenu />
      <Booking />
      <Footer />
    </div>
  )
}

export default Home
