import { ref, update } from "firebase/database";
import { db } from "../../../../firebase";

export const UpdateCounterOffer = async (idBooking, idDriver, price) => {
  try {
    const refPath = `BookingBusiness/${idBooking}`;
    const refDB = ref(db, refPath);
    const newPrice = {
      idDriver: idDriver,
      price: Number(price),
    };
    console.log(refPath);
    console.log(newPrice);
    await update(refDB, newPrice);
  } catch (error) {
    console.log(error);
  }
};
