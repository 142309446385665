import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import TopMenu from "../../../components/topbar/TopMenu";
import Footer from "../../../components/footer/Footer";

import HistoryPendingLater from "./HistoryPendingLater";
import HistoryPendingNow from "./HistoryPendingNow";
import HistoryQuoteLater from "./HistoryQuoteLater";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HistoryTab = () => {
  const [value, setValue] = useState(0);
  const theme = createTheme();
  const { t } = useTranslation();
  const handleChange = (event, newValue) => setValue(newValue);
  //?id=trpus9rewdmpkuk9ix6c

  return (
    <>
      <TopMenu />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Booking-Tabs"
                centered
                textColor="primary"
                indicatorColor="#ffffff"
                sx={{
                  "& .MuiTabs-flexContainer": { flexWrap: "wrap" },
                }}
              >
                <Tab
                  label={t("Ahora")}
                  {...a11yProps(0)}
                  wrapped
                  sx={{ color: "#000000", fontSize: 15, fontWeight: "bold" }}
                />
                <Tab
                  label={t("Cotizar")}
                  {...a11yProps(0)}
                  wrapped
                  sx={{ color: "#000000", fontSize: 15, fontWeight: "bold" }}
                />
                <Tab
                  label={t("Reservas")}
                  {...a11yProps(0)}
                  wrapped
                  sx={{ color: "#000000", fontSize: 15, fontWeight: "bold" }}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <HistoryPendingNow />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <HistoryQuoteLater />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <HistoryPendingLater />
            </TabPanel>
          </Box>
        </Container>
      </ThemeProvider>
      <Footer />
    </>
  );
};

export default HistoryTab;
