import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";

import Header from "../../../components/header/Header";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import {
  getToken,
  decodeRefreshToken,
  decodeDataToken,
} from "../../../helpers/token/refreshToken";
import { db } from "../../../firebase";
import { onValue, ref, update } from "firebase/database";
import { convertDate } from "../../../helpers/date/convertDate";
import { useTranslation } from "react-i18next";
import { generateOpenPayURL } from "../../../api/openpay/openpay";
import { getAdvanceCashOrder } from "../../../api/booking/advance";

const HistoryPendingLater = () => {
  const [booking, setBooking] = useState([]);
  const { t } = useTranslation();
  const dataToken = getToken("dttkn");
  const { enterprise_id, sub_role } = decodeDataToken(dataToken);
  const [listAdvance, setListAdvance] = useState([]);

  const currency = { 1: "PEN", 2: "USD" };

  useEffect(() => {
    const token = getToken("etknre");
    const uid = decodeRefreshToken(token);
    const starCountRef = ref(db, "BookingBusiness/");

    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        const compareByDate = (a, b) => {
          const dateB = convertDate(b.date, a.time);
          const dateA = convertDate(a.date, a.time);
          return dateA - dateB;
        };

        setBooking([]);
        setListAdvance([]);
        Object.values(data)
          .sort(compareByDate)
          .map(async (_booking) => {
            if (sub_role === "ADMIN") {
              if (
                _booking.booking_type != 1 &&
                Number(_booking.enterpriseId) === Number(enterprise_id) &&
                _booking.cotizar
              ) {
                _booking.currency = currency[_booking.currency];
                _booking.fullorigin = _booking.origin.name;
                _booking.fulldestination = _booking.destination.name;
                setBooking((oldArray) => [...oldArray, _booking]);
              }
            } else if (
              _booking.booking_type != 1 &&
              Number(_booking.client) === uid &&
              _booking.cotizar
            ) {
              _booking.currency = currency[_booking.currency];
              _booking.fullorigin = _booking.origin.name;
              _booking.fulldestination = _booking.destination.name;
              setBooking((oldArray) => [...oldArray, _booking]);
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    if (booking.length > 0) {
      const getOrderPay = async () => {
        setListAdvance([]);
        booking.map(async (item) => {
          const resp = await getAdvanceCashOrder(item.counterBooking);
          const data = resp.data.resp;
          console.log(data);
          if (data.length > 0) {
            const list = { orderNumber: item.counterBooking, data };
            setListAdvance((oldData) => [...oldData, list]);
          }
        });
      };

      getOrderPay();
    }
  }, [booking]);

  const model = ["", "Sedan", "SUV", "Minivan"];
  const [open, setOpen] = React.useState(false);
  const [uidX, setUid] = useState("");
  const [bookingLater, setBookingLater] = useState();
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const dateOpenpayFormat = () => {
    var fecha = new Date();

    var año = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agregar un cero inicial si es necesario
    var dia = ("0" + fecha.getDate()).slice(-2); // Agregar un cero inicial si es necesario
    var hora = ("0" + fecha.getHours()).slice(-2); // Agregar un cero inicial si es necesario
    var minuto = ("0" + fecha.getMinutes()).slice(-2); // Agregar un cero inicial si es necesario
    var segundo = ("0" + fecha.getSeconds()).slice(-2); // Agregar un cero inicial si es necesario

    var formatoFecha =
      año + "-" + mes + "-" + dia + "T" + hora + ":" + minuto + ":" + segundo;

    return formatoFecha;
  };

  const generateOpenPayForm = async (booking) => {
    try {
      const dataToken = getToken("dttkn");
      const { name, lastname, phone, email } = decodeDataToken(dataToken);
      const orderID = `${booking.counterBooking}-${Date.now()}`;
      const redirectUrl = `https://business.taximundo.com/voucher/${booking.currency}`;
      // const redirectUrl = `http://localhost:3003/voucher/${booking.currency}`;

      const bookingOpenPay = {
        method: "card",
        amount: Number(booking.advance),
        currency: booking.currency,
        description: `${booking.counterBooking}. PAGO POR SERVICIO DE TRANSPORTE`,
        order_id: orderID,
        confirm: "false",
        send_email: "false",
        redirect_url: redirectUrl,
        due_date: dateOpenpayFormat(),
        customer: {
          name: name,
          last_name: lastname,
          phone_number: phone,
          email: email,
        },
      };

      const response = await generateOpenPayURL(bookingOpenPay);
      if (response.data.message === "success") {
        const data = response.data.data;
        const url = response.data.data.payment_method.url;
        window.open(url, "_blank");
        const openpayData = {
          transaction_id: data.id,
          order_id: data.order_id,
          createBy: "B",
        };
        await update(
          ref(db, `BookingBusiness/${booking.uuid}/openpay`),
          openpayData
        );
      } else {
        alert("ERROR");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatClientName = (name) => {
    const nameSplit = name.split(" ");
    if (nameSplit.length === 2) {
      return name;
    }
    if (nameSplit.length === 3) {
      return `${nameSplit[0]} ${nameSplit[1]}`;
    }
    if (nameSplit.length > 3) {
      return `${nameSplit[0]} ${nameSplit[2]}`;
    }
    return name;
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={""} subtitle={t("booking_pending")} />
      </Box>

      {booking.length > 0 ? (
        booking.map((booking, index) => (
          <Card key={index} sx={{ border: 1, margin: "1px -3px 2px -3px" }}>
            {/* <CardHeader sx={{ mt: -1 }} /> */}
            <CardContent>
              <Grid container>
                <Grid item xs={4} sm={6}>
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    TM-{booking.counterBooking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={booking.booking_type === 3 ? 3 : 6}
                  textAlign={"end"}
                >
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    {booking.date} - {booking.time}
                  </Typography>
                </Grid>
                {booking.booking_type === 3 && (
                  <Grid item xs={12} sm={3} textAlign={"end"}>
                    <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                      {booking.dateEnd} - {booking.timeEnd}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    A Origen: {booking.origin.name}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"error"}
                    sx={{ fontWeight: "bold" }}
                  >
                    B Destino: {booking.destination.name}
                  </Typography>
                </Grid>
                {booking.driver ? (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Conductor: {booking.driver.name} {booking.driver.lastname}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Vehicle: {booking.driver.brand} {booking.driver.model} -
                      {booking.driver.plate}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Vehicle: {model[booking.vehicle]}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Persons: {booking.persons}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  {sub_role === "ADMIN" && (
                    <Typography
                      color={"primary"}
                      sx={{ fontSize: "13px", fontWeight: "bold" }}
                    >
                      {formatClientName(booking.clientName)}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Nota: {booking.note}
                  </Typography>
                </Grid>

                {listAdvance.map((item, index) => {
                  console.log("item", item);
                  const check =
                    Number(booking.counterBooking) == Number(item.orderNumber);

                  if (check) {
                    return (
                      <div key={index}>
                        {item.data.map((adv, idx) => {
                          return (
                            <div
                              key={idx}
                              style={{ width: "100%", fontWeight: "bold" }}
                            >
                              {idx + 1}.- {adv.currency} {adv.amount}{" "}
                              {adv.status} {adv.operation_date}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                })}
              </Grid>
            </CardContent>
            <CardActions sx={{ mt: -2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    <DriveEtaOutlinedIcon />
                    &nbsp;
                    {booking.openpay
                      ? booking.openpay?.status === "pending"
                        ? "PENDIENTE"
                        : "PAGADO"
                      : "PENDIENTE"}
                  </p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    {booking.currency} {booking.total}
                  </p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Button
                    fullWidth
                    size="small"
                    color="info"
                    variant="contained"
                    sx={{ fontWeight: "bold", background: "#128C7E" }}
                    href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${booking.counterBooking},%0A *Cliente:* ${booking.clientName},%0A *Origen:* ${booking.origin.name}, *Destino:* ${booking.destination.name} `}
                    target={"_blank"}
                    onClick={(e) => {
                      setUid(booking.uuid);
                    }}
                  >
                    Consultar&nbsp;
                    <WhatsAppIcon />
                  </Button>
                </Grid>
                {/*   */}
                {booking.total > 0 &&
                  booking.openpay &&
                  booking.openpay.btnPay && (
                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="primary"
                        variant="contained"
                        sx={{ fontWeight: "bold" }}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setOpen(true);
                          setUid(booking.uuid);
                          setBookingLater(booking);
                        }}
                      >
                        PAGAR&nbsp;
                        <ContactlessOutlinedIcon />
                      </Button>
                      {/* CREAR LA FUNCIONALIDAD PARA PAGAR */}
                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            PAGAR PEDIDO?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                                generateOpenPayForm(bookingLater);
                              }}
                            >
                              CONFIRMAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => setOpen(false)}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>
                  )}
              </Grid>
            </CardActions>
          </Card>
        ))
      ) : (
        <div>{t("NO HAY RESERVAS PENDIENTES")}</div>
      )}
    </>
  );
};

export default HistoryPendingLater;
