import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Title from "./pdf/Title";
import Contact from "./pdf/Contact";
import TitleDetails from "./pdf/TitleDetails";
import EnterpriseName from "./pdf/EnterpriseName";
import {
  decodeDataToken,
  getToken,
} from "../../../../helpers/token/refreshToken";
import TableVertical from "./pdf/vertical/TableVertical";
import TableHorizontal from "./pdf/horizontal/TableHorizontal";

const GenerateReport = ({ position, bookingData }) => {
  const styles = StyleSheet.create({
    pageVert: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    pageHoriz: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  const dataToken = getToken("dttkn");
  const { igv, commertial_name, name, lastname } = decodeDataToken(dataToken);
  const contactInfo = {
    invoice_no: "201906-28",
    company: "TAXIMUNDO",
    email: "info@taximundo.com",
    phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
    address: "Servicios Generales Trans Mundo E.I.R.L.",
  };

  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    if (bookingData.length > 0) {
      let sumTotal = 0;
      let sumToll = 0;

      Object.values(bookingData).map((book) => {
        sumToll += Number(book.toll);
        sumTotal += Number(book.price_total);
      });

      let imp = 0;
      let totalPay = 0;
      let subTotal = 0;
      if (igv === "MAS IGV") {
        imp =
          Math.round((Number(sumTotal) + Number(sumToll)) * 0.18 * 100) / 100;
        subTotal = Math.round((Number(sumTotal) + Number(sumToll)) * 100) / 100;
        totalPay =
          Math.round((Number(sumTotal) + Number(sumToll) + Number(imp)) * 100) /
          100;
      } else if (igv === "INAFECTO") {
        totalPay = Math.round((Number(sumTotal) + Number(sumToll)) * 100) / 100;
      } else if (igv === "AFECTO") {
        subTotal = Math.round((sumTotal / 1.18) * 100) / 100;
        imp = Math.round((sumTotal - subTotal) * 100) / 100;
        totalPay = Math.round((Number(sumTotal) + Number(sumToll)) * 100) / 100;
      }

      setTableData([]);
      setTableData({
        id: "5df3180a09ea16dc4b95f910",
        invoice_no: "201906-28",
        items: bookingData,
        igv: imp,
        total: totalPay,
        peaje: sumToll,
        subTotal: subTotal,
        from: bookingData[bookingData.length - 1].date,
        to: bookingData[0].date,
      });
    }
  }, [bookingData]);

  return (
    <>
      {position === "horizontal" && (
        <PDFDownloadLink
          document={
            <Document>
              <Page size={[841.89, 595.28]} style={styles.pageHoriz}>
                <Title title="Taxi Mundo PERU" />
                <Contact invoice={contactInfo} />
                <TitleDetails
                  title={"DETALLE DE SERVICIOS"}
                  from={"bookingData.from"}
                  to={"bookingData.to"}
                />
                <EnterpriseName
                  enterprise={commertial_name}
                  name={name}
                  lastname={lastname}
                />
                {tableData && <TableHorizontal invoice={tableData} />}
              </Page>
            </Document>
          }
          fileName={"detalles-taximundo.pdf"}
        >
          <Box width={"100%"} textAlign={"center"}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ height: 45 }}
            >
              <CloudDownloadIcon />
              <Typography
                marginLeft={0.5}
                sx={{ color: "white", fontWeight: "bold" }}
              >
                PDF
              </Typography>
            </Button>
          </Box>
        </PDFDownloadLink>
      )}

      {position === "vertical" && (
        <PDFDownloadLink
          document={
            <Document>
              <Page size="A4" style={styles.pageVert}>
                <Title title="Taxi Mundo PERU" />
                <Contact invoice={contactInfo} />
                <TitleDetails
                  title={"DETALLE DE SERVICIOS"}
                  from={"tableData.from"}
                  to={"tableData.to"}
                />
                <EnterpriseName
                  enterprise={commertial_name}
                  name={name}
                  lastname={lastname}
                />
                {tableData && <TableVertical invoice={tableData} />}
              </Page>
            </Document>
          }
          fileName={"detalles-taximundo.pdf"}
        >
          <Box width={"100%"} textAlign={"center"}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ height: 45 }}
            >
              <CloudDownloadIcon />
              <Typography
                marginLeft={0.5}
                sx={{ color: "white", fontWeight: "bold" }}
              >
                PDF
              </Typography>
            </Button>
          </Box>
        </PDFDownloadLink>
      )}
    </>
  );
};

export default GenerateReport;
